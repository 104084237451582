(function(scope, undef){
    "use strict"; 
    var w = scope.window, af = scope.af, c = af.common;

    function Procedure(pOptions) {
        var that = this, priv = {
            options: new c.Options({
                defaults: {
                    articleId: af.article && af.article.id,
                    parameters: null, // [ { name: "ParameterName", type: "string", length: 20, required: true } ]
                    timeout: 30000,
                    workingText: null,
                    transaction: true,
                    api:0
                },
                required: ["procedureId","procedure"],
                passed: pOptions

            }),
            eventHandler: new c.EventHandler(that, {
                onBeforeExecute: { abortable: true }, // Fires when the procedure succeds
                onSuccess: { abortable: false }, // Fires when the procedure succeds
                onError: { abortable: true }, // Fires when the procedure fails
                onComplete: { abortable: false }, // Fires after the procedure completes
                onCancel: { abortable: false } //fires after data-action="cancel" is triggered
            }),
            parameterNames: [],
            values:{},
            xhr:null
        };

        function fireCallback(pCallback, pError, pData) {
            if (typeof pCallback === "function") {
                return pCallback.call(that, pError, pData);
            }
        }

        function fireEvent(pEvent, pArgs, pModifiableArgs) {
            return priv.eventHandler.fire(pEvent, pArgs, pModifiableArgs);
        }
        function attachEvent(pEvent, pFunc) {
            return priv.eventHandler.attach(pEvent, pFunc);
        }
        function detachEvent(pEvent, pFunc) {
            return priv.eventHandler.detach(pEvent, pFunc);
        }

        function toLowerCase(str) {
            return str.toLowerCase();
        }

        function validateData(pData) {
			
			// disabled for now as it was a bit too eager in throwing errors...
			return;
			/*
            var vDataKeys = c.getObjKeys(pData), vLowerKeys = vDataKeys.map(toLowerCase);
            c.forEach(priv.options.parameters, function(i, param) {
                var vDataKeyIndex, vDataKey, vDataValue, vType;
                vDataKeyIndex = vLowerKeys.indexOf(param.name.toLowerCase());
                vDataKey = vDataKeys[vDataKeyIndex];
                vDataValue = pData[vDataKey];*/
                /*
                // NOTE(janhenrik): has_default_value from sys.parameters seems to always be 0, so we cannot check if parameter is required
                if (param.required && (vDataKeyIndex === -1 || vDataValue === null || vDataValue === undef)) {
                    throw new Error("Missing required parameter '" + param.name + "' when executing procedure");
                }
                */
                /*if (vDataValue === null || vDataValue === undef) { return; }
                vType = c.typeOf(vDataValue);
                if (vType === "string" && param.type === "number") {
                    if (isNaN(vDataValue)) {
                        throw new TypeError("Incorrect type of supplied parameter '" + param.name + "' when executing procedure");
                    }
                } else {
                    if (vType !== null && vType !== param.type) {
                        throw new TypeError("Incorrect type of supplied parameter '" + param.name + "' when executing procedure");
                    }
                    if (vType === "string" && param.maxLength && vDataValue.length > param.maxLength) {
                        throw new Error("Value of supplied parameter '" + param.name + "' was too long");
                    }
                }
            });
            c.forEach(vLowerKeys, function (i, key) {
                if (!~priv.parameterNames.indexOf(key)) {
                    throw new Error("Invalid parameter supplied when executing procedure");
                }
            });*/
        }
        function setTimeout(pTimeout){
            priv.options.timeout = pTimeout;
        }

        function parseResultsFromTable(pData){
            var vReturn  = [];
            Object.values(pData).forEach(tbl=>{
                vReturn.push(tbl);
            });

            return vReturn;
        }
        

        function execute(pData, pCallback, pSynchronous) {
            /**
             * Executes the stored procedure using the supplied parameters
             * @param {object} pData The parameters to execute the procedure with
             * @param {function} pCallback The function to execute upon completion
             * @param {boolean} pSynchronous Set to true to execute synchronously
             */
            
            var vData = {};
            var vArgs = arguments; // Parse / shift arguments to support
            // optional parameter signatures: (pCallback, pSynchronous) | (pSynchronous) )
            if (typeof pData === "boolean") { pSynchronous = pData; pCallback = null; } // (pSynchronous)
            if (typeof pCallback === "boolean") { pSynchronous = pCallback; } // (?, pSynchronous)
            if (typeof pData === "function") { pCallback = pData; } // (pCallback, ?)
            if (typeof pSynchronous !== "boolean") { pSynchronous = false; } // (?, ?, false)
            if (typeof pCallback !== "function") { pCallback = null; } // (?, null, ?)
            if (c.typeOf(pData) !== "object") { pData = {}; } // ({}, ?, ?)
            vData.values = pData;
            // Validate passed parameters
            if (priv.options.parameters) { validateData(pData); } // Throws an error if pData is not valid
            var vWorking, vReturnValue;
            if (priv.options.workingText && typeof af.controls.working === "function") {
                vWorking = af.controls.working(priv.options.workingText);
            }
            
            
            vData.operation = "execute";
            //pData.resourceName = priv.options.procedureId;
            
            vData.procedure =  priv.options.procedure;
            vData.timeout = priv.options.timeout >= 1000 ? priv.options.timeout / 1000 : priv.options.timeout;
            if (priv.options.transaction === false){
                vData.transaction = false;
            }
            vData.excludeFieldNames = true;
            //pData.timeout = priv.options.timeout;
            
            if(fireEvent("onBeforeExecute", vData.values, true) !== false){
                priv.xhr = scope.$.ajax({
                type: "POST",
                async: !pSynchronous,
                url: "/api/data/" + priv.options.procedure,
                timeout: priv.options.timeout > 0 ? priv.options.timeout * 1000 : priv.options.timeout,
                data: JSON.stringify(vData),
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (response, status, xhr) {
                        if (c.objHasKey(response, "success")) {
                            //priv.values = {};
                            pData = {};
                            fireEvent("onSuccess", parseResultsFromTable(response.success));
                            fireCallback(pCallback, null, parseResultsFromTable(response.success));
                            if (pSynchronous) { vReturnValue = response.success; }
                        } else if (c.objHasKey(response, "error")) {
                            if (scope.console && typeof scope.console.log === "function" && c.objHasKey(response, "stack")) {
                                if (typeof scope.console.groupCollapsed === "function" && typeof scope.console.groupEnd === "function") {
                                    scope.console.groupCollapsed(priv.options.procedureId + ":", response.error);
                                    scope.console.log(response.stack);
                                    scope.console.groupEnd();
                                } else {
                                    scope.console.log([priv.options.procedureId + ": " + response.error, response.stack].join("\n"));
                                }
                            }
                            fireEvent("onError", response.error);
                            if (fireCallback(pCallback, response.error, null) !== false) {
                                alert(response.error,"error");
                            }
                            if (pSynchronous) { vReturnValue = response.error; }
                        } else {
                            if (scope.console && typeof scope.console.log === "function") {
                                scope.console.log(priv.options.procedureId + ": " + xhr.status + " " + xhr.statusText + ": Unexpected response from server:\n" + xhr.responseText);
                            }
                            fireEvent("onError", "Unspecified error");
                            if (fireCallback(pCallback, "Unspecified error", null) !== false) {
                                alert("Unspecified error while executing procedure","error");
                            }
                            if (pSynchronous) { vReturnValue = "Unspecified error"; }
                        }
                    },
                    error: function (xhr) {
                        var vError = af.data.getResponseError.call(this, xhr, function () { execute.apply(that, vArgs); });
                        if (typeof vError === "string") {
                            vError = c.getLocalizedString(vError,true);
                            var vShowToast = fireEvent("onError", vError) !== false;
                            if (fireCallback(pCallback, vError, null) !== false && vShowToast) {
                                af.controls.errorDialog(vError, xhr.responseJSON?.errorType);
                            }
                            if (pSynchronous) { vReturnValue = vError; }
                        }
                    },
                    complete: function (xhr) {
                        var vError = af.data.getResponseError.call(this, xhr, function () { execute.apply(that, vArgs); });
                        if (typeof vWorking === "function") { vWorking(); }
                        fireEvent("onComplete");
                        if(xhr.statusText === "timeout"){
                            if (fireCallback(pCallback, "The execution timed out", null) !== false) {
                                new af.controls.errorDialog(vError, xhr.responseJSON?.errorType);
                            }
                        }
                    }
                });
            } else {
                fireCallback(pCallback);
            }

            return vReturnValue;
        }
        
        function execute2(pCallback, pSynchronous){
            var vObject = JSON.parse(JSON.stringify(priv.values));
            execute(vObject, pCallback, pSynchronous);
        }
        
        function parameterValue(name, value){
            if(arguments.length > 1){
                priv.values[name] = value;
            }else if(arguments.length === 1){
                return priv.values[name];
            }else{
                console.log("argument missing for parameter value");
            }
        }
        
        function getValues(){
            return priv.values;
        }

        function getParameters() {
            return c.copyArray(priv.options.parameters);
        }
        
        function cancelExecute(){
            if(priv.xhr){
                priv.xhr.abort();
            }
            priv.values = {};
            fireEvent("onCancel");
        }

        function hasRequiredParameters() {
            var p = priv.options.parameters, l = p.length, i;
            for (i = 0; i < l; i++) {
                if (p[i].required) { return true; }
            }
            return false;
        }

        function initialize() {
            if (priv.options.parameters) {
                c.forEach(priv.options.parameters, function(i, param) {
                    if (!c.objHasKey(param, "name")) { throw new Error("Missing name of supplied parameter"); }
                    if (typeof param.name !== "string") { throw new TypeError("Invalid name of suppplied parameter"); }
                    priv.parameterNames.push(param.name.toLowerCase());
                });
            }
        }

        c.expose(that, [attachEvent, detachEvent, execute,execute2, getParameters, hasRequiredParameters, parameterValue, getValues, cancelExecute, setTimeout]);

        initialize();
    }

    c.expose("af", Procedure);

}(this));